import { BackendToType, TypeToBackend } from 'constants/FormFieldType';

// Transforms to allow many existing frontend components to communicate with newer backend endpoints
// Should refactor the FE but it gets pretty hairy

const mapFieldFromBackend = field => ({
  ...field,
  type: BackendToType[field.kind] || 'button',
  options: {
    ...field.options,
    required: field.required,
    value: field.options.text,
    multiline: field.options.multi_line || false,
    fullWidth: field.options.multi_line || ['paragraph', 'divider'].includes(field.kind) || false,
    hasCheckbox: field.options.checkbox_enabled || false,
    checkboxLabel: field.options.checkbox_label,
    placeholder: field.options.placeholder || (field.kind === 'date_picker' ? 'Select date' : undefined),
    // Discard dropdown ID (normally sent as the value) and use readable string instead
    items: field.options.items?.map(({ label }) => ({ label, value: label }))
  }
});

const mapFieldToBackend = (field, tab, keeper) => {
  return {
    ...field,
    kind: TypeToBackend[field.type],
    required: field.options?.required || false,
    mappers: [
      ...(field.options?.customAttributeId
        ? [{ custom_attribute_id: field.options?.customAttributeId, keeper }]
        : field.mappers || [])
    ],
    options: {
      ...field.options,
      text: field.options?.value,
      multi_line: field.options?.multiline || false,
      checkbox_enabled: field.options?.hasCheckbox || false,
      checkbox_label: field.options?.checkboxLabel
    },
    registration_form_tab_id: tab.id
  };
};

export { mapFieldFromBackend, mapFieldToBackend };
