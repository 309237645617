import React, { useState, useEffect, useRef } from 'react';
import SYNC_RUNNER_TYPES from 'constants/syncRunnerTypes';
import get from 'lodash/get';
import { getSyncName } from 'lib/utils';
import { ActionButton, TooltipTrigger } from 'components';
import './style.scss';
import { connect } from 'react-redux';
import moment from 'moment';

const POLL_INTERVAL_MINUTES = 1;
const MAX_SYNC_WAIT_MINUTES = 60;

const STATUS_ACTIVE = 'active',
  STATUS_UNAVAILABLE = 'unavailable',
  STATUS_AVAILABLE = 'default';

const SyncButton = ({ syncPESetting }) => {
  const pollTimer = useRef(null);
  const [shouldReload, setShouldReload] = useState(true);
  const [syncStatus, setSyncStatus] = useState(STATUS_UNAVAILABLE);
  const [syncError, setSyncError] = useState();

  const isSyncing = () => syncPESetting && syncPESetting.in_progress;

  const requiresReload = () => shouldReload || !syncPESetting;

  const requiresPolling = () => isSyncing() && !syncError;

  const startPolling = () => {
    if (pollTimer.current) {
      return;
    }

    pollTimer.current = setInterval(() => {
      setShouldReload(true);
    }, POLL_INTERVAL_MINUTES * 60 * 1000);
  };

  const stopPolling = () => {
    pollTimer.current && clearInterval(pollTimer.current);
    pollTimer.current = null;
  };

  const syncAvailable = () => syncPESetting && !isSyncing() && moment().isSameOrAfter(syncPESetting.next_available_at);

  const setStatusState = syncPESetting => {
    // The sync is currently running
    if (isSyncing()) {
      // Stop polling if we've waited past the max wait time.
      const syncDuration = moment().diff(syncPESetting.last_sync_run.started_at, 'minutes');
      if (syncDuration > MAX_SYNC_WAIT_MINUTES) {
        stopPolling();
        setSyncStatus(STATUS_UNAVAILABLE);
        setSyncError('Sync failed to complete');
        return;
      }

      // All is well, show that we're syncing.
      setSyncStatus(STATUS_ACTIVE);
    } else {
      // If the sync is no longer running, but we're in an active state, the sync is done.
      if (syncStatus === STATUS_ACTIVE) {
        Actions.showFlash('Data has been synced');
      }

      // Set our sync state based on availability of another sync.
      const available = syncAvailable();
      return available ? setSyncStatus(STATUS_AVAILABLE) : setSyncStatus(STATUS_UNAVAILABLE);
    }
  };

  const isDeltaAllowed = () => syncPESetting.delta_allowed;

  useEffect(() => {
    if (requiresReload()) {
      req
        .syncPESettings({
          type: 'pe'
        })
        .then(() => setShouldReload(false))
        .catch(err => {
          setShouldReload(false);
          Actions.reportError('Unable to sync', err);
        });
    } else {
      setStatusState(syncPESetting);
      requiresPolling() ? startPolling() : stopPolling();
    }

    return () => stopPolling();
  }, [shouldReload]);

  const showModal = async () => {
    if (!syncAvailable()) {
      return;
    }

    const syncName = getSyncName(syncPESetting.data_provider);
    const confirmSync = await Actions.showModal('Confirmation', {
      title: `Sync with ${syncName}`,
      description: `Please confirm all updates have been made in ${syncName}. Sync can be enabled one time per hour.`,
      yesButton: 'Sync',
      noButton: 'Cancel',
      yesButtonProps: {
        secondary: false
      },
      noButtonProps: {
        secondary: true
      }
    });

    if (confirmSync) {
      sync();
    }
  };

  const sync = async () => {
    try {
      // Start a sync and begin polling.
      await req.syncPERuns({
        id: syncPESetting.id,
        runner_type: isDeltaAllowed() ? SYNC_RUNNER_TYPES.DELTA : SYNC_RUNNER_TYPES.DEFAULT
      });
      setShouldReload(true);
      Actions.showFlash('Sync has been initiated');
    } catch (err) {
      Actions.reportError('Unable to sync', err);
    }
  };

  const getTooltipText = () => {
    if (!syncPESetting || !syncPESetting.last_sync_run) {
      return;
    }

    if (syncError) {
      return syncError;
    }

    if (isSyncing()) {
      return 'Sync is in progress';
    }

    if (!syncAvailable()) {
      return `Last sync at ${Helpers.formatTime(
        syncPESetting.last_sync_run.completed_at
      )}. Next sync available at ${Helpers.formatTime(syncPESetting.next_available_at)}`;
    }

    return '';
  };

  const TooltipWrapper = ({ children }) => {
    const tooltip = getTooltipText();
    return syncAvailable() ? (
      children
    ) : (
      <TooltipTrigger
        className="topbar-sync__tooltip topbar-sync__tooltip--wider"
        tooltip={tooltip}
        side="center"
        triggerOn="hover"
      >
        {children}
      </TooltipTrigger>
    );
  };

  return (
    !!syncPESetting && (
      <TooltipWrapper>
        <ActionButton
          className={`topbar-action-button topbar-sync topbar-sync--${syncStatus}`}
          iconName="sync"
          size={25}
          onClick={showModal}
        />
      </TooltipWrapper>
    )
  );
};

const mapStateToProps = state => ({
  syncPESetting: get(state, 'syncPESetting.data[0]'),
  currentUser: state.currentUser.data
});

export default connect(mapStateToProps)(SyncButton);
