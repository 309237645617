const MIN_WIDTH = 768;
const MIN_HEIGHT = 415;

const ScreenState = {
  Mobile: 'mobile',
  Desktop: 'desktop'
};

class ViewportObserver {
  static viewport = null;
  static state = null;

  static getNextState() {
    return screen.availWidth < MIN_WIDTH || screen.availHeight < MIN_WIDTH ? ScreenState.Mobile : ScreenState.Desktop;
  }

  static handleResize() {
    if (this.viewport === null) {
      return;
    }

    this.state = this.getNextState();
    this.viewport.setAttribute('content', this.getViewportContent(this.state));
  }

  static getViewportContent(state) {
    const screenHeight = screen.availHeight;
    const initialScale = 0.5; // Workaround to make menu visible in mobile landscape mode

    switch (state) {
      case ScreenState.Desktop:
        return 'width=device-width, initial-scale=1';

      case ScreenState.Mobile:
        return screenHeight >= MIN_HEIGHT ? `width=${MIN_WIDTH}` : `width=${MIN_WIDTH}, initial-scale=${initialScale}`;
    }
  }

  static observe(viewport) {
    this.viewport = viewport;

    this.handleResize();
    window.addEventListener('resize', () => this.handleResize());
  }
}

export default ViewportObserver;
