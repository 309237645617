import React, { useState } from 'react';
import classNames from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CAPTCHA_ACTIONS from 'constants/captchaActions';
import currency from 'lib/currency';
import { ButtonV2, Preloader } from 'components';
import './style.scss';

const sections = [
  { key: 'student_info', name: 'Student' },
  { key: 'parent_info', name: 'Parent' },
  { key: 'authorized_pickup_info', name: 'Authorized Pickup' },
  { key: 'policy_and_waiver', name: 'Policy & Waiver' }
];

function Summary({ form, school, responses, respondentId, payment, onBack, ...props }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onContinue = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const recaptchaToken = await executeRecaptcha(CAPTCHA_ACTIONS.REGISTRATION_PAYMENT);

      let paymentResult;

      if (payment) {
        const saveCardResponse = await Actions.showModal('RegistrationFormPaymentMethod', {
          school,
          slug: form.slug
        });

        if (!saveCardResponse) {
          setIsSubmitting(false);
          return;
        }

        setIsProcessingPayment(true);

        const createPaymentResponse = await req.createPaymentForRegistrationFormResponse({
          form_id: form.id,
          id: respondentId,
          recaptcha_token: recaptchaToken,
          payment: saveCardResponse
        });

        // Package results for success screen
        try {
          if (school.billing_gateway === 'te') {
            const tokenObj = JSON.parse(saveCardResponse.token);
            paymentResult = {
              ccLastFourDigits: tokenObj.lastfour || 'Unknown',
              ccExpiration: tokenObj.expiry ? tokenObj.expiry.slice(0, 2) + '/' + tokenObj.expiry.slice(2) : null
            };
          } else {
            paymentResult = {
              ccLastFourDigits: saveCardResponse.last4 || 'Unknown',
              ccExpiration: null
            };
          }
        } catch (err) {
          paymentResult = {
            ccLastFourDigits: 'Unknown',
            ccExpiration: null
          };
        }
        try {
          paymentResult.amount = currency.getPrice(createPaymentResponse?.respondent?.amount) ?? 'Unknown';
        } catch (err) {
          paymentResult.amount = 'Unknown';
        }
      }

      const submitFormResponse = await req.submitRegistrationFormResponse({
        form_id: form.id,
        id: respondentId,
        recaptcha_token: recaptchaToken
      });
      props.onContinue({
        form: submitFormResponse,
        payment: paymentResult
      });
    } catch (err) {
      if (err.response?.status === 403) {
        // Recaptcha fail
        Actions.showFlash(
          'Unable to submit registration. Please try again or contact your center for assistance.',
          'alert'
        );
      } else {
        Actions.reportError('There was a problem submitting the registration', err);
      }
    } finally {
      setIsSubmitting(false);
      setIsProcessingPayment(false);
    }
  };

  const renderResponse = response => {
    if ([null, undefined, ''].includes(response) || (Array.isArray(response) && response.length === 0)) {
      return 'NONE';
    }

    if (Array.isArray(response)) {
      return response.map((r, i) => <div key={i}>{typeof r === 'object' && 'name' in r ? r.name : r}</div>);
    }

    return response;
  };

  const renderSection = section => {
    const subsections = responses[section.key];
    const cn = classNames('registration-form-summary__responses-subsection', {
      'registration-form-summary__responses-subsection--indented': subsections.length > 1
    });

    return subsections.map((subsection, i) => (
      <React.Fragment key={i}>
        {subsections.length > 1 && (
          <div className="registration-form-summary__responses__response-key mt-16">
            {section.name} #{i + 1}
          </div>
        )}
        <div className={cn}>
          {subsection.map(({ label, response }) => (
            <div key={label} className="registration-form-summary__responses__response">
              <div className="registration-form-summary__responses__response-key">{label}</div>
              <div className="registration-form-summary__responses__response-value">{renderResponse(response)}</div>
            </div>
          ))}
        </div>
      </React.Fragment>
    ));
  };

  const renderResponses = () =>
    sections
      .filter(section => responses[section.key]?.length)
      .map(section => (
        <div key={section.key} className="registration-form-summary__responses-section">
          <div className="registration-form-summary__responses-section-header">{section.name} Info</div>

          {renderSection(section)}
        </div>
      ));

  const renderPayments = () => (
    <div className="registration-form-summary__payment">
      <div className="registration-form-summary__payment-header">Registration Fees</div>
      <div className="registration-form-summary__payment-grid">
        {payment.registration_fees.map((fee, i) => (
          <React.Fragment key={i}>
            <div className="registration-form-summary__payment-lineitem">Student {i + 1} registration fee</div>
            <div className="registration-form-summary__payment-fee">${fee}</div>
          </React.Fragment>
        ))}
        <div className="registration-form-summary__payment-lineitem registration-form-summary__payment-totals">
          Subtotal
        </div>
        <div className="registration-form-summary__payment-fee">${payment.subtotal}</div>
        <div className="registration-form-summary__payment-lineitem">Total</div>
        <div className="registration-form-summary__payment-fee">${payment.total}</div>
      </div>
      {/* Only applicable to logged-in carers - not supported till phase 2
       <div className="registration-form-summary__payment-method-header">Payment method</div>
      <div className="registration-form-summary__payment-method">
        <div>Credit card ending in: XXXX</div>
        <small>Expires XX/XX</small>
      </div> */}
    </div>
  );

  return (
    <div className="registration-form-summary content-container">
      <div className="registration-form-summary__header">{form.name}</div>
      <div className="registration-form-summary__subheader">Review Registration</div>
      <div className="registration-form-summary-content">
        <div className="registration-form-summary__responses">{renderResponses()}</div>
        {!!payment && renderPayments()}
      </div>
      <div className="content-container__controls">
        <ButtonV2 secondary label="Back" onClick={onBack} disabled={isProcessingPayment || isSubmitting} />
        <ButtonV2
          label={payment ? 'Pay & Submit' : 'Submit'}
          onClick={onContinue}
          disabled={!executeRecaptcha || isProcessingPayment || isSubmitting}
        />
      </div>
      {isProcessingPayment && (
        <div className="registration-form-summary__processing">
          <Preloader large center />
        </div>
      )}
    </div>
  );
}

export default Summary;
