import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { GenericEvents } from '@newrelic/browser-agent/features/generic_events';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';

/* Sample usage: 
    NrBrowserAgent && NrBrowserAgent.noticeError('Dashboard sample render error', { user: this.props.currentUser });
    NrBrowserAgent && NrBrowserAgent.addPageAction('Legacy login', { result: 'success' });
 */
export function connectNewRelic() {
  try {
    const options = {
      init: {
        session_replay: {
          enabled: true,
          block_selector: '',
          mask_text_selector: '*',
          sampling_rate: 10.0,
          error_sampling_rate: 100.0,
          mask_all_inputs: true,
          collect_fonts: true,
          inline_images: false,
          inline_stylesheet: true,
          mask_input_options: {}
        },
        distributed_tracing: { enabled: true },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ['bam.nr-data.net'] }
      },
      info: {
        applicationID: process.env.NEWRELIC_APPLICATION_ID,
        beacon: 'bam.nr-data.net',
        errorBeacon: 'bam.nr-data.net',
        licenseKey: process.env.NEWRELIC_LICENSE_KEY,
        sa: 1
      },
      loader_config: {
        accountID: process.env.NEWRELIC_ACCOUNT_ID,
        agentID: process.env.NEWRELIC_AGENT_ID,
        applicationID: process.env.NEWRELIC_APPLICATION_ID,
        licenseKey: process.env.NEWRELIC_LICENSE_KEY,
        trustKey: process.env.NEWRELIC_TRUST_KEY
      },
      features: [Spa, PageViewEvent, PageViewTiming, SessionTrace, GenericEvents, Metrics, JSErrors]
    };

    const browserAgent = new BrowserAgent(options);
    if (!process.env.APPLICATION_VERSION) {
      browserAgent.noticeError('Application version was not set');
    } else {
      browserAgent.addRelease(process.env.APPLICATION_COMMIT_ID, process.env.APPLICATION_VERSION);
    }

    return browserAgent;
  } catch (e) {
    console.error(e);
  }
}
