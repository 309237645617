import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import Rollbar from 'rollbar';
import { isMobile } from 'lib/utils';
import { connect } from 'react-redux';
import './style.scss';
import history from 'lib/history';
import Loading from '../Loading';
import ErrorPage from '../ErrorPage';
import Register from 'screens/common/Register';
import Qrcode from 'screens/common/Qrcode';
import DocumentsSignature from 'screens/common/DocumentSignature';
import { batchActions } from 'redux-batched-actions';
import RegisterV2 from '../RegisterV2';
import { RootContext } from 'hocs/withContext';

class Root extends Component {
  static propTypes = {
    currentUser: PropTypes.object
  };

  constructor() {
    super();

    this.state = {
      constantsLoaded: false,
      chunkLoaded: false,
      hasError: false,
      Component: null
    };
  }

  getRootContext() {
    return {
      isMobile: isMobile(),
      logout: this.logout,
      switchRole: this.switchRole
    };
  }

  UNSAFE_componentWillMount() {
    this.loadConstants();
    this.initComponent(this.props.currentUser);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const prevUser = this.props.currentUser;
    const nextUser = nextProps.currentUser;

    if (
      prevUser.id !== nextUser.id ||
      prevUser.current_role !== nextUser.current_role ||
      (prevUser.current_school && nextUser.current_school && prevUser.current_school.id !== nextUser.current_school.id)
    ) {
      this.loadConstants();
      this.initComponent(nextUser);
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ hasError: true });
  }

  @bind
  loadConstants() {
    this.setState({ constantsLoaded: false });

    req
      .constants()
      .then(() => {
        this.setState({ constantsLoaded: true });
      })
      .catch(() => {
        this.setState({ constantsLoaded: true });
      });
  }

  @bind
  initComponent(user) {
    this.setState({ chunkLoaded: false });

    const klPath = process.env.KL_PUBLIC_PATH;
    const publicPath = location.href.replace('kinderlime.com', 'procareconnect.com');

    // check for Procare
    if (klPath && klPath === location.hostname) {
      if (!user || !user.id) {
        window.location.href = publicPath;
      } else {
        req
          .impersonate_token()
          .then(res => {
            window.location.href = res.url;
          })
          .catch(e => {
            Actions.reportError('There was problem redirecting', e);
            window.location.href = publicPath;
          });
      }
      return;
    }

    if (!user.id) {
      import(/* webpackChunkName: "auth" */ '../Auth').then(Auth => {
        this.setState({ Component: Auth.default, chunkLoaded: true });
      });
    }

    if (process.env.ROLLBAR_ENV === 'production') {
      Rollbar.configure({
        payload: {
          person: {
            id: user.id,
            username: user.name,
            email: user.email
          }
        }
      });
    }

    if (user.current_role === 'interim_auth') {
      import(/* webpackChunkName: "select-role" */ '../Auth/InterimAuth').then(InterimAuth => {
        this.setState({ Component: InterimAuth.default, chunkLoaded: true });
      });
    }

    if (user.current_role === 'teacher') {
      if (user.current_school && user.current_school.corp_school) {
        import(/* webpackChunkName: "corporate" */ 'screens/corporate/CorporateApp').then(CorporateApp => {
          this.setState({ Component: CorporateApp.default, chunkLoaded: true });
        });
      } else {
        import(/* webpackChunkName: "teacher" */ 'screens/teacher/TeacherApp').then(TeacherApp => {
          this.setState({ Component: TeacherApp.default, chunkLoaded: true });
        });
      }
    } else if (user.current_role === 'carer') {
      import(/* webpackChunkName: "carer" */ 'screens/carer/CarerApp').then(CarerApp => {
        this.setState({ Component: CarerApp.default, chunkLoaded: true });
      });
    }
  }

  @bind
  logout() {
    this.setState({ chunkLoaded: false });
    import(/* webpackChunkName: "auth" */ '../Auth').then(Auth => {
      this.setState({ Component: Auth.default, chunkLoaded: true });
      req.logout();
      this.props.logout();
      history.push('/login');
    });
  }

  @bind
  switchRole(role, schoolId) {
    this.setState({ chunkLoaded: false }, async () => {
      const nextUser = await req.changeSchool({ auth_for: role, school_id: schoolId });
      this.props.replaceUser(nextUser);

      if (role === 'carer') {
        const CarerApp = await import(/* webpackChunkName: "carer" */ 'screens/carer/CarerApp');
        this.setState({ Component: CarerApp.default, chunkLoaded: true });
      } else {
        const TeacherApp = await import(/* webpackChunkName: "teacher" */ 'screens/teacher/TeacherApp');
        this.setState({ Component: TeacherApp.default, chunkLoaded: true });
      }

      history.push('/dashboard');
    });
  }

  get appLoaded() {
    return this.state.chunkLoaded && this.state.constantsLoaded;
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return (
      <RootContext.Provider value={this.getRootContext()}>
        <Router history={history}>
          <Switch>
            <Route path="/register/:slug" component={Register} />
            <Route path="/form/:id" component={RegisterV2} />
            <Route path="/qrcode" component={Qrcode} />
            <Route exact path="/registration-signature-request/:token" component={DocumentsSignature} />
            <Route path="/" component={this.appLoaded ? this.state.Component : Loading} />
          </Switch>
        </Router>
      </RootContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: 'REDUX_STORE_RESET' }),
  replaceUser: user => {
    dispatch(
      batchActions([
        { type: 'REDUX_STORE_RESET', payload: { exclude: ['CONSTANTS', 'CURRENT_USER'] } },
        { type: 'CURRENT_USER_SET', payload: user }
      ])
    );
  }
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(Root);
