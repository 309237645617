function load() {
  if (!process.env.BING_ID) {
    return;
  }

  (function(w, d, t, r, u) {
    var f, n, i;
    (w[u] = w[u] || []),
      (f = function() {
        var o = { ti: process.env.BING_ID };
        (o.q = w[u]), (w[u] = new window.UET(o)), w[u].push('pageLoad');
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.async = 1),
      (n.onload = n.onreadystatechange = function() {
        var s = this.readyState;
        (s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null));
      }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i);
  })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
}

function track(event = 'event', action = 'Event action', params = {}) {
  window.uetq = window.uetq || [];
  window.uetq.push(event, action, params);
}

export default {
  track,
  load
};
