import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { ActionButton, ButtonV2, FormRenderer } from 'components';
import './style.scss';

class FormListV2 extends Component {
  static propTypes = {
    itemName: PropTypes.string.isRequired,
    fields: PropTypes.array,
    items: PropTypes.array,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    limit: PropTypes.number,
    schoolId: PropTypes.string
  };

  static defaultProps = {
    fields: [],
    items: [],
    limit: 10
  };

  static createItem() {
    return { id: v4() };
  }

  forms = {};

  constructor(props) {
    super(props);

    this.state = {
      items: props.items.length > 0 ? props.items : [FormListV2.createItem()]
    };
  }

  handleChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.items);
    }
  }

  @bind
  addItem() {
    const nextItems = [...this.state.items];
    nextItems.push(FormListV2.createItem());
    this.setState({ items: nextItems }, this.handleChange);
  }

  @bind
  updateItem(itemId) {
    return values => {
      const nextItems = [...this.state.items];
      const itemIndex = nextItems.findIndex(i => i.id === itemId);
      nextItems[itemIndex] = { ...nextItems[itemIndex], ...values };

      this.setState({ items: nextItems }, this.handleChange);
    };
  }

  @bind
  deleteItem(itemId) {
    const { onDelete } = this.props;

    return () =>
      onDelete().then(res => {
        if (!res) {
          return;
        }

        const { items } = this.state;
        const itemIndex = items.findIndex(k => k.id === itemId);
        const nextItems = [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)];

        this.setState({ items: nextItems }, this.handleChange);
      });
  }

  renderItems() {
    const { items } = this.state;
    const { itemName, fields, onDelete, schoolId, onRestart } = this.props;

    return items.map((i, index) => (
      <div className="form-list__item" key={i.id} data-cy={'form-item'}>
        <div className="row row--wide form-list__item-header">
          <div className="form-list__item-label">
            {itemName} #{index + 1}
          </div>

          <ActionButton
            hidden={!onDelete || index === 0}
            iconName="delete"
            data-cy={'delete'}
            size={14}
            disabled={items.length === 1}
            onClick={this.deleteItem(i.id)}
          />
        </div>

        <FormRenderer
          ref={this.bindForm(i.id)}
          className="form-list__item-form"
          fields={fields}
          defaultValues={i}
          schoolId={schoolId}
          onRestart={onRestart}
          onChange={this.updateItem(i.id)}
        />
      </div>
    ));
  }

  @bind
  bindForm(id) {
    return node => (this.forms[id] = node);
  }

  @bind
  isFormValid() {
    return Object.keys(this.forms)
      .map(k => this.forms[k])
      .every(f => !f || f.isFormValid());
  }

  render() {
    const { limit, itemName } = this.props;
    const { items } = this.state;
    const addItemsDisabled = items.length >= limit;

    return (
      <div className="form-list-v2">
        {this.renderItems()}

        <ButtonV2
          tertiary
          className="form-list-v2__add-item-btn"
          data-cy="add-item"
          label={`Add ${itemName}`}
          disabled={addItemsDisabled}
          onClick={this.addItem}
        />

        {addItemsDisabled && (
          <div className="form-list-v2__label">
            Only {limit} {itemName}s are allowed
          </div>
        )}
      </div>
    );
  }
}

export default FormListV2;
