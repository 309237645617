import React from 'react';
import Topbar from '../../common/Topbar';
import { Flashes } from 'components';
import './style.scss';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="app__inner">
        <Topbar
          onSwitchSchool={() => {
            return;
          }}
        />

        <section id="main-section" className="section document-signature__section">
          <div className="document-signature">
            <div className="document-signature__content mt-24">
              <div className="document-signature__raised-container">{children}</div>
            </div>
          </div>
        </section>

        <Flashes />
      </div>
    </React.Fragment>
  );
};

export default Layout;
