import { useSelector } from 'react-redux';

function usePermissions() {
  const staffPermissions = useSelector(state => state.currentUser.data.permissions || {});
  const mergedPermissions = useSelector(state => state.currentUser.data.mergedPermissions || {});

  const hasPermissions = (name, value, shouldIgnoreSchoolPermissions) => {
    const permissions = shouldIgnoreSchoolPermissions ? staffPermissions : mergedPermissions;

    if (Array.isArray(value)) {
      return value.includes(permissions[name]);
    } else {
      return permissions[name] === value;
    }
  };

  const hasReadPermissions = (name, shouldIgnoreSchoolPermissions) =>
    hasPermissions(name, ['read', 'write'], shouldIgnoreSchoolPermissions);
  const hasWritePermissions = (name, shouldIgnoreSchoolPermissions) =>
    hasPermissions(name, 'write', shouldIgnoreSchoolPermissions);

  return { hasReadPermissions, hasWritePermissions };
}

export default usePermissions;
