import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, StepProgressBarV2, ButtonV2, FormRenderer, Icon } from 'components';
import { renderAddress } from 'lib/utils';
import FormFieldType from 'constants/FormFieldType';
import { mapFieldFromBackend } from 'screens/teacher/Registration/Editor/StepCreateForm/utils';
import { validateValues } from './utils';
import FormListV2 from './FormListV2';
import Summary from './Summary';
import './style.scss';
import useLaunchDarkly from 'hooks/useLaunchDarkly';

function RegistrationForm({ form, isPreview, ...props }) {
  const [tabs] = useState(
    form.tabs.map(t => ({
      ...t,
      label: t.name, // StepProgressBar expects obj with 'name' and 'label'
      fields: t.fields.map(mapFieldFromBackend)
    }))
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [values, setValues] = useState(
    Array(tabs.length)
      .fill(null)
      // Tabs can have repeating groups (Student #1, Student #2) which are an array of objects
      .map((_, i) => (tabs[i].options.repeat_index_label ? [{ id: 'first' }] : {}))
  );
  const [respondentId, setRespondentId] = useState(undefined);
  const [responsesSummary, setResponsesSummary] = useState(null);
  const [paymentSummary, setPaymentSummary] = useState(null);
  const [successResponse, setSuccessResponse] = useState(null);
  const formRefs = useRef([]);
  const flags = useLaunchDarkly();

  const school = props.school || form.school;
  const currentTab = tabs[currentTabIndex];
  const constants = useSelector(state => state.constants.data);
  const { countries } = constants;

  const onChange = newValues => {
    setValues(values.map((v, i) => (i === currentTabIndex ? newValues : v)));
  };

  const onDelete = () => {
    const itemName = currentTab.options.repeat_index_label;

    return Actions.showModal('Confirmation', {
      title: `Delete ${itemName}`,
      description: `Are you sure you want to delete this ${itemName}?`
    });
  };

  const onRestartForm = () => {
    setCurrentTabIndex(0);
  };

  const isTabValid = (index = currentTabIndex) => {
    if (isPreview) {
      return currentTabIndex < tabs.length - 1;
    }

    if (tabs[index].hidden && flags['online_cms_advancedregp2b']) {
      return true;
    }

    // Check <Form> validation rules (IsEmail, etc)
    if (formRefs.current[index]?.isFormValid() === false) {
      return false;
    }

    // Check "required" fields manually - cannot be rolled into normal form
    // validation because Registration needs to support previewing the form
    const requiredFieldIds = tabs[index].fields
      .filter(f => (f.type === FormFieldType.TextBox ? f.options.hasCheckbox : f.options.required))
      .map(f => f.id);

    if (Array.isArray(values[index])) {
      return values[index].length > 0 && values[index].every(val => validateValues(requiredFieldIds, val));
    } else {
      return validateValues(requiredFieldIds, values[index]);
    }
  };

  const onChangeStep = index => {
    for (let i = currentTabIndex; i < index; ++i) {
      if (!isTabValid(i)) {
        Actions.showFlash('All required fields should be filled before advancing to the next page', 'alert');
        return;
      }
    }

    setCurrentTabIndex(index);
  };

  const onContinue = async () => {
    if (!isTabValid()) {
      Actions.showFlash('All required fields should be filled before advancing to the next page', 'alert');
      return;
    }

    let nextVisibleTabIndex;

    for (let i = currentTabIndex + 1; i < tabs.length; i++) {
      if (tabs[i].hidden && flags['online_cms_advancedregp2b']) {
        continue;
      }

      nextVisibleTabIndex = i;
      break;
    }

    if (nextVisibleTabIndex) {
      setCurrentTabIndex(nextVisibleTabIndex);
    } else {
      const mapFields = (fields, index) =>
        Object.entries(fields)
          // Ignore ID helper used by form renderer
          .filter(([key]) => key !== 'id')
          .map(([key, value]) => ({
            field_id: key,
            response: value ?? '',
            index: index
          }));

      const responses = values.flatMap(tab => (Array.isArray(tab) ? tab.flatMap(mapFields) : mapFields(tab)));

      try {
        const response = await req.createRegistrationFormResponses({
          form_id: form.id,
          responses,
          // respondent_id is undefined on first call, then set by
          // the response and reused for any additional calls
          respondent_id: respondentId
        });

        setRespondentId(response.respondent_id);
        setResponsesSummary(response.responses_summary);
        setPaymentSummary(Number(response.payment_summary?.total) > 0 ? response.payment_summary : null);
      } catch (err) {
        Actions.reportError('There was a problem submitting your responses.', err);
      }
    }
  };

  const renderCurrentStep = () => {
    const SubForm = currentTab.options.repeat_index_label ? FormListV2 : FormRenderer;

    return (
      <SubForm
        ref={el => (formRefs.current[currentTabIndex] = el)}
        key={currentTabIndex}
        fields={currentTab.fields}
        items={currentTab.options.repeat_index_label ? values[currentTabIndex] : undefined}
        defaultValues={currentTab.options.repeat_index_label ? undefined : values[currentTabIndex]}
        limit={5}
        itemName={currentTab.options.repeat_index_label}
        onChange={onChange}
        onDelete={onDelete}
        onRestart={onRestartForm}
        schoolId={form.school_id || school.id}
      />
    );
  };

  const cancelSummary = () => {
    setResponsesSummary(null);
    setPaymentSummary(null);
  };

  const handleSuccess = response => {
    setSuccessResponse(response);
  };

  if (successResponse) {
    const hasPayment = !!successResponse.payment;

    return (
      <div className="content-container">
        <div className="registration-form__success">
          <div className="registration-form__success-image">
            <Icon name="party-streamers" className="registration-form__success-image-streamers" size={240} />
            <Icon name="tucker" className="registration-form__success-image-tucker" size={149} />
          </div>
          <div className="registration-form__success-header">{hasPayment ? 'Payment' : 'Registration'} Successful</div>
          <div className="registration-form__success-message">
            Thank you for submitting your registration{hasPayment && ' fee'}
          </div>
          {hasPayment && (
            <div className="registration-form__success-payment">
              <div className="registration-form__success-payment-lineitem">
                <div className="registration-form__success-payment-lineitem-key">Amount Paid</div>
                <div className="registration-form__success-payment-lineitem-value">
                  {successResponse.payment.amount}
                </div>
              </div>
              <div className="registration-form__success-payment-lineitem registration-form__success-payment-lineitem--method">
                <div className="registration-form__success-payment-lineitem-key">Payment Method</div>
                <div className="registration-form__success-payment-lineitem-value">
                  <div>Credit card ending in: {successResponse.payment.ccLastFourDigits}</div>
                  {successResponse.payment.ccExpiration && <span>Expires {successResponse.payment.ccExpiration}</span>}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (responsesSummary) {
    return (
      <Summary
        form={form}
        school={school}
        responses={responsesSummary}
        respondentId={respondentId}
        payment={paymentSummary}
        onBack={cancelSummary}
        onContinue={handleSuccess}
      />
    );
  }

  const steps = flags['online_cms_advancedregp2b'] ? tabs.filter(t => !t.hidden) : tabs;

  return (
    <div className="registration-form content-container">
      <div className="registration-form__school">
        <Avatar
          className="avatar--square"
          name={school.name}
          url={school.profile_pic_url}
          type="logo"
          size="large"
          placeholder="School Logo"
        />
        <div className="registration-form__school-info">
          <div className="registration-form__school-info-name">{school.name}</div>

          <div className="registration-form__school-info-address">{renderAddress(school, countries)}</div>
        </div>
      </div>
      <hr />
      <div className="registration-form__header">{form.name}</div>
      <StepProgressBarV2 steps={steps} currentStep={currentTab.name} onClick={onChangeStep} />
      {renderCurrentStep()}
      <div className="content-container__controls">
        <ButtonV2 label="Continue" disabled={!isTabValid()} onClick={onContinue} />
      </div>
    </div>
  );
}

export default RegistrationForm;
