export function validatePresence(value) {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.trim().length > 0;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return value !== undefined && value !== null;
}

export function validateValues(requiredFieldNames, values) {
  return requiredFieldNames.map(n => values[n]).every(validatePresence);
}
