import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import { validateValues } from './utils';
import FormList from './FormList';

class KidInfo extends Component {
  static propTypes = {
    fields: PropTypes.array,
    items: PropTypes.array,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  constructor(props) {
    super(props);

    this.state = {
      items: props.items
    };
  }

  @bind
  updateItems(items) {
    this.setState({ items });
  }

  @bind
  validate() {
    const { fields } = this.props;
    const { items } = this.state;
    const requiredFieldNames = fields.filter(f => f.options.required).map(f => f.id);

    return items.length > 0 && items.every(i => validateValues(requiredFieldNames, i));
  }

  @bind
  submit() {
    const isValid = this.validate();

    if (!isValid) {
      Actions.showFlash('All required fields should be filled', 'alert');
      return;
    }

    this.props.onNext();
  }

  @bind
  saveItems() {
    this.props.onSave('kidInfo', this.state.items);
  }

  showDeleteMsg() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Student',
      description: 'Do you want to delete student?'
    });
  }

  render() {
    const { fields, schoolId, onRestart } = this.props;
    const { items } = this.state;

    return (
      <div className="register-form__kid-info">
        <FormList
          fields={fields}
          items={items}
          limit={5}
          itemName="Student"
          onChange={this.updateItems}
          onDelete={this.showDeleteMsg}
          onRestart={onRestart}
          schoolId={schoolId}
        />

        <div className="register-form__controls">
          <ButtonV2 data-cy="continue" label="Continue" onClick={this.submit} />
        </div>
      </div>
    );
  }
}

export default KidInfo;
