import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Topbar from 'screens/common/Topbar';
import { Flashes, ButtonV2, Preloader } from 'components';
import ModalController from 'modals/ModalController';
import ViewportObserver from 'lib/ViewportObserver';
import { getQuery } from 'lib/history';
import './style.scss';

class QrcodePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      code: null,
      expire: getQuery().expire
    };
  }

  componentDidMount() {
    const viewport = document.getElementById('viewport');
    const { expire } = this.state;

    if (viewport) {
      ViewportObserver.observe(viewport);
    }

    this.loadQrCode();

    if (!expire) {
      this.interval = setInterval(() => {
        this.loadQrCode();
      }, 2 * 60 * 60 * 1000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  @bind
  async loadQrCode() {
    const { expire } = this.state;
    const expireTime = expire ? -moment().diff(moment(expire, 'YYYY-MM-DDTh:mm A'), 'second') : undefined;
    try {
      const resp = await req.qrcode({ expiry_time: expireTime });
      this.setState({ code: `data:image/png;base64,${resp.code.data}`, loading: false }, () =>
        // Timeout needed to ensure image has loaded
        setTimeout(() => {
          if (expire) {
            window.print();
          }
        }, 0)
      );
    } catch (e) {
      Actions.reportError('There was problem generating QR code', e);
    }
  }

  @bind
  printCode() {
    Actions.showModal('PrintQrCode');
  }

  render() {
    const { loading, code, expire } = this.state;
    const { currentUser } = this.props;

    return (
      <div className="qrcode">
        <Topbar />

        <div className="qrcode__inner">
          <div className="qrcode__title">{get(currentUser, 'data.current_school.name')}</div>
          {!expire && <ButtonV2 label="Print QR Code" className="qrcode__print-btn" onClick={this.printCode} />}
          <div className="qrcode__desc">Scan QR code with your device to Sign-In or Sign-Out.</div>
          {loading ? (
            <div className="qrcode__content">
              <Preloader large />
            </div>
          ) : (
            <div className="qrcode__content">
              <img src={code} width="370" height="370" />
              <div className="qrcode__note">
                {!expire
                  ? 'Refreshes every 2 hours'
                  : `Expires at: ${moment(expire, 'YYYY-MM-DDTh:mm A').format('MMM DD, YYYY. h:mm A')}`}
              </div>
            </div>
          )}
        </div>
        <ModalController />
        <Flashes />
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser
});

export default connect(mapStateToProps)(QrcodePage);
