import React, { Component } from 'react';
import { Icon } from 'components';
import './style.scss';

class RegisterComplete extends Component {
  UNSAFE_componentWillMount() {
    const { location, history } = this.props;
    const fromRegister = location && location.state ? location.state.fromRegister : false;

    if (!fromRegister) {
      history.replace('/');
    }
  }

  render() {
    return (
      <div className="register-complete">
        <div className="register-complete__inner">
          <Icon name="tick" size={60} className="register-complete__icon" />

          <div className="register-complete__title">Thank you</div>

          <div className="register-complete__description">
            Your registration information is submitted to the school.
          </div>

          <a href="https://www.procaresoftware.com" className="button register-complete__go-back-btn">
            Visit Procare
          </a>
        </div>
      </div>
    );
  }
}

export default RegisterComplete;
