import React, { Component } from 'react';
import { TabView, Flashes, Preloader, Avatar } from 'components';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ModalController from 'modals/ModalController';
import KidInfo from './KidInfo';
import AuthorizedPickupInfo from './AuthorizedPickupInfo';
import Review from './Review';
import ParentInfo from 'screens/common/RegisterForm/ParentInfo';
import PolicyWavier from 'screens/common/RegisterForm/PolicyWavier';
import FormFieldType from 'constants/FormFieldType';
import { renderAddress } from 'lib/utils';
import history from 'lib/history';
import './style.scss';

const tabOrder = ['kidInfo', 'parentInfo', 'authorizedPickupInfo', 'policyWavier', 'review'];
const kidBasicFields = [
  'id',
  'first_name',
  'last_name',
  'dob',
  'time_schedule',
  'expected_birth',
  'allergy',
  'country_code',
  'region',
  'city',
  'street_address',
  'zip'
];
const carerBasicFields = ['id', 'first_name', 'last_name', 'email', 'mobile_phone'];
const pickupBasicFields = ['id', 'first_name', 'last_name', 'actual_relation', 'mobile_phone'];

class RegisterForm extends Component {
  tabFormRefs = {
    kidInfo: React.createRef(),
    parentInfo: React.createRef(),
    authorizedPickupInfo: React.createRef(),
    policyWavier: React.createRef()
  };

  constructor(props) {
    super(props);

    this.state = {
      schoolReg: {},
      values: {},
      completed: {},
      activeTabIndex: 0,
      loading: false,
      disabled: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.loadSchoolRegForm().then(() => this.setState({ loading: false }));
    });
  }

  loadSchoolRegForm() {
    return req
      .schoolRegForm({ slug: this.props.match.params.slug })
      .then(res => {
        if (res.redirect) {
          history.push(`/form/${res.form_id}`);
          this.setState({ disabled: true });
          return;
        }

        this.setState({ schoolReg: res });
      })
      .catch(() => {
        this.setState({ disabled: true });
      });
  }

  getTab(tabName) {
    return this.tabFormRefs[tabName] ? this.tabFormRefs[tabName].current : undefined;
  }

  validateTab(tabName) {
    const tab = this.getTab(tabName);
    return tab === null ? undefined : tab.validate();
  }

  @bind
  updateTabValues(tabName, tabValues, cb) {
    const { values, completed } = this.state;
    const isStepValid = this.validateTab(tabName);

    this.setState(
      {
        values: { ...values, [tabName]: tabValues },
        completed: { ...completed, [tabName]: isStepValid }
      },
      cb
    );
  }

  @bind
  changeTab(nextTabIndex) {
    const { activeTabIndex, completed } = this.state;

    if (nextTabIndex === activeTabIndex) {
      return;
    }

    if (nextTabIndex > activeTabIndex) {
      let unfinishedFurtherSteps = false;

      for (let i = activeTabIndex; i < nextTabIndex; i++) {
        const tabName = tabOrder[i];
        const isCompleted = completed[tabName];
        const isValid = this.validateTab(tabName);

        if (isValid && !isCompleted) {
          this.setState({ completed: { ...completed, [tabName]: isValid } });
        }

        if (!isCompleted && !isValid) {
          unfinishedFurtherSteps = true;
          break;
        }
      }

      if (unfinishedFurtherSteps) {
        Actions.showFlash('Please finish all steps before the selected step', 'alert');
        return;
      }
    }

    const currentTab = this.getTab(tabOrder[activeTabIndex]);

    if (currentTab && currentTab.saveItems) {
      currentTab.saveItems();
    }

    this.setState({ activeTabIndex: nextTabIndex });
  }

  @bind
  restartForm() {
    this.changeTab(0);
  }

  @bind
  prevTab() {
    this.changeTab(this.state.activeTabIndex - 1);
  }

  @bind
  nextTab() {
    this.changeTab(this.state.activeTabIndex + 1);
  }

  serializeFields(basicFields, data, formFields) {
    const basicFieldsMap = basicFields.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: undefined
      }),
      {}
    );

    return data.map(itemData => {
      const itemPayload = {
        form_fields: {},
        custom_attributes: {}
      };

      Object.keys(itemData).forEach(fieldId => {
        const value = itemData[fieldId];

        if (Object.prototype.hasOwnProperty.call(basicFieldsMap, fieldId)) {
          return (itemPayload[fieldId] = value);
        }

        const formField = formFields.find(f => f.id === fieldId);
        const fieldOptions = formField.options;

        if (fieldOptions.customAttributeId) {
          itemPayload.custom_attributes[fieldOptions.customAttributeId] =
            FormFieldType.Select === formField.type ? [value] : value;
        } else {
          itemPayload.form_fields[fieldId] = value;
        }
      });

      return itemPayload;
    });
  }

  makePayload() {
    const { schoolReg, values } = this.state;

    const kidsPayload = this.serializeFields(kidBasicFields, values.kidInfo, schoolReg.form.kidInfo);
    const carersPayload = this.serializeFields(carerBasicFields, values.parentInfo, schoolReg.form.parentInfo);
    const pickupPayload = this.serializeFields(
      pickupBasicFields,
      values.authorizedPickupInfo,
      schoolReg.form.authorizedPickupInfo
    );

    return {
      slug: schoolReg.share.slug,
      kids: kidsPayload,
      enrollment: {
        carers_attributes: carersPayload,
        pickups_attributes: pickupPayload,
        custom_steps: values.policyWavier
      }
    };
  }

  @bind
  sendForm(data) {
    const payload = this.makePayload();

    return req.submitSchoolRegForm({
      ...payload,
      ...data
    });
  }

  renderLoading() {
    return (
      <div className="register-form__loading">
        <Preloader large />
      </div>
    );
  }

  renderDisabled() {
    return <div className="register-form__disabled-msg">Your school registration is not available at the moment</div>;
  }

  render() {
    const { schoolReg, values, completed, loading, disabled, activeTabIndex, constantsLoading } = this.state;
    const { countries } = this.props.constants;

    if (loading || constantsLoading) {
      return this.renderLoading();
    }

    if (disabled) {
      return this.renderDisabled();
    }

    const school = schoolReg.school || {};
    const form = schoolReg.form || {};

    return (
      <GoogleReCaptchaProvider
        useEnterprise
        scriptProps={{ async: true }}
        reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
      >
        <div className="register-form" data-cy="register-form">
          <div className="register-form__inner">
            <div className="register-form__school">
              <Avatar
                className="avatar--square"
                name={school.name}
                url={school.profile_pic_url}
                type="logo"
                size="large"
                placeholder="School Logo"
              />

              <div className="register-form__school-info">
                <div className="register-form__school-info-name">{school.name}</div>

                <div className="register-form__school-info-address">{renderAddress(school, countries)}</div>
              </div>
            </div>

            <TabView
              onChange={this.changeTab}
              disableNextSteps
              showIndices
              className="register-form__tabs"
              activeTabIndex={activeTabIndex}
            >
              <TabView.Tab
                id="kidInfo"
                _ref={this.tabFormRefs.kidInfo}
                title="Student info"
                completed={completed.kidInfo}
              >
                <KidInfo
                  fields={form.kidInfo}
                  items={values.kidInfo}
                  onSave={this.updateTabValues}
                  onNext={this.nextTab}
                  onRestart={this.restartForm}
                  schoolId={school.id}
                />
              </TabView.Tab>

              <TabView.Tab
                id="parentInfo"
                _ref={this.tabFormRefs.parentInfo}
                title="Parent info"
                completed={completed.parentInfo}
              >
                <ParentInfo
                  fields={form.parentInfo}
                  items={values.parentInfo}
                  onSave={this.updateTabValues}
                  onBack={this.prevTab}
                  onNext={this.nextTab}
                  onRestart={this.restartForm}
                  schoolId={school.id}
                />
              </TabView.Tab>

              <TabView.Tab
                id="authorizedPickupInfo"
                _ref={this.tabFormRefs.authorizedPickupInfo}
                title="Authorized Pickup Info"
                completed={completed.authorizedPickupInfo}
              >
                <AuthorizedPickupInfo
                  ref={this.tabFormRefs.authorizedPickupInfo}
                  fields={form.authorizedPickupInfo}
                  items={values.authorizedPickupInfo}
                  onSave={this.updateTabValues}
                  onBack={this.prevTab}
                  onNext={this.nextTab}
                  onRestart={this.restartForm}
                  schoolId={school.id}
                />
              </TabView.Tab>

              <TabView.Tab
                id="policyWavier"
                _ref={this.tabFormRefs.policyWavier}
                title="Policy & Waiver"
                completed={completed.policyWavier}
              >
                <PolicyWavier
                  fields={form.policyWavier}
                  values={values.policyWavier}
                  onSave={this.updateTabValues}
                  onBack={this.prevTab}
                  onNext={this.nextTab}
                  onRestart={this.restartForm}
                  schoolId={school.id}
                />
              </TabView.Tab>

              <TabView.Tab id="review" title="Review">
                <Review
                  schoolReg={schoolReg}
                  values={values}
                  onBack={this.prevTab}
                  onRestart={this.restartForm}
                  onSubmit={this.sendForm}
                />
              </TabView.Tab>
            </TabView>

            <ModalController />
            <Flashes />
          </div>
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants.data,
  constantsLoading: state.constants.loading
});

export default connect(mapStateToProps)(RegisterForm);
