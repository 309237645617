import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { connectRollbar } from './rollbar';
import { connectNewRelic } from './newrelic';

import apiRoutes from './apiRoutes';
import authRoutes from './authRoutes';
import ActionsObject from './actions';
import Helpers from './helpers';
import bing from './lib/bing';
import history from 'lib/history';
import Req from './lib/req';
import { generateIPAddress } from 'lib/utils';
import configureStore from './configureStore';
import Root from './screens/common/Root';
import { createRoot } from 'react-dom/client';

// polyfill of window.stop on IE
// ISSUE: https://stackoverflow.com/a/1286712
if (!window.stop) {
  window.stop = () => document.execCommand('Stop');
}

connectRollbar();
global.NrBrowserAgent = connectNewRelic();

const initialize = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  global.Actions = new ActionsObject(store);
  global.Helpers = new Helpers(store);
  global.req = Req.create({
    authRoutes,
    apiRoutes,
    store,

    fallbackApiUrl: process.env.API_URL,

    getHeaders: () => {
      return {
        'Requested-From': generateIPAddress()
      };
    },

    handleError: error => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            store.dispatch({ type: 'REDUX_STORE_RESET' });
            break;

          // TODO: Enable this after release
          // case 403:
          //   location.reload();
          //   break;

          case 406:
            store.dispatch({ type: 'REDUX_STORE_RESET' });
            history.push('/redirecting');
            window.location.href = error.response.data.redirect_url;
            break;

          case 409:
            Actions.showFlash(error.response.data.errors.join('. '), 'alert');
            break;
        }
      }
    }
  });

  const createProviderContainer = Component => {
    const container = (
      <Provider store={store}>
        <Component />
      </Provider>
    );

    if (process.env.LAUNCHDARKLY_CLIENT_SIDE_ID) {
      const FeatureFlagProvider = withLDProvider({
        clientSideID: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID,
        reactOptions: {
          useCamelCaseFlagKeys: false
        }
      })(() => container);

      return FeatureFlagProvider;
    } else {
      return () => container;
    }
  };

  if (process.env.BING_ID) {
    bing.load();
  }

  const rootDomEl = document.getElementById('root-app');
  if (isProduction) {
    if (process.env.FACEBOOK_PIXEL_ID) {
      try {
        ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();

        if (!window.localStorage.getItem('facebookPixel')) {
          window.localStorage.setItem('facebookPixel', true);
          ReactPixel.fbq('track', 'PixelInitialized');
        }
      } catch (e) {
        // console.error(e);
      }
    }
  }

  const Container = createProviderContainer(Root);
  const root = createRoot(rootDomEl);
  root.render(<Container />);
};

const store = configureStore(initialize);
