import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ButtonV2, FormSummary } from 'components';
import currency from 'lib/currency';
import Stickyfill from 'stickyfilljs';
import { getErrorText } from 'lib/utils';
import CAPTCHA_ACTIONS from 'constants/captchaActions';
import Loading from '../Loading';

class Review extends Component {
  static propTypes = {
    schoolReg: PropTypes.object,
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    onRestart: PropTypes.func
  };

  static defaultProps = {
    schoolReg: {},
    values: {}
  };

  payEl = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  get isRecaptchaNotLoaded() {
    return !this.props.googleReCaptchaProps.executeRecaptcha;
  }

  componentDidMount() {
    if (this.payEl) {
      Stickyfill.addOne(this.payEl);
    }
  }

  componentWillUnmount() {
    if (this.payEl) {
      Stickyfill.removeOne(this.payEl);
    }
  }

  @bind
  bindPayEl(node) {
    this.payEl = node;
  }

  @bind
  async submit() {
    const { payment } = this.props.schoolReg;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    const captchaToken = await executeRecaptcha(CAPTCHA_ACTIONS.LEAD_SUBMISSION);

    if (payment && payment.enabled) {
      Actions.showModal('SchoolRegistrationPayment', {
        ...this.props.schoolReg,
        kidCount: this.props.values.kidInfo.length
      }).then(paymentInfo => {
        if (paymentInfo) {
          this.finish({
            recaptcha_token: captchaToken,
            payment: {
              ...paymentInfo,
              amount: Number(payment.amount),
              description: payment.description
            }
          });
        }
      });
    } else {
      this.finish({
        recaptcha_token: captchaToken
      });
    }
  }

  @bind
  finish(data) {
    const {
      share: { slug }
    } = this.props.schoolReg;

    this.setState({ loading: true });
    this.props
      .onSubmit(data)
      .then(() => {
        this.props.history.replace(`/register/${slug}/complete`, { fromRegister: true });
      })
      .catch(async err => {
        if (err.response) {
          // recaptcha error
          if (err.response.status === 403) {
            await Actions.showModal('Alert', {
              title: 'Unable to Submit Registration.',
              message:
                'We are unable to submit your registration at this time. Please contact your center for assistance.',
              label: 'Okay'
            });

            this.props.onRestart();
          } else {
            Actions.reportError(getErrorText(err.response.data), err);
          }
        }
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;
    const { schoolReg, values, onBack } = this.props;
    const payment = schoolReg.payment || {};
    const form = schoolReg.form || {};
    const authorizedPickupInfo = values.authorizedPickupInfo || [];

    const btnLabel = payment.enabled ? 'Pay and submit' : 'Submit';

    if (loading) {
      return <Loading>Submitting your registration form...</Loading>;
    }

    return (
      <div className="register-form__review">
        <div className="register-form__review-row">
          <div className="register-form__review-col">
            <FormSummary
              data-cy="kid-summary"
              title="Student Info"
              fields={form.kidInfo}
              values={values.kidInfo}
              itemName="student"
            />
            <FormSummary
              data-cy="parent-summary"
              title="Parent Info"
              fields={form.parentInfo}
              values={values.parentInfo}
              itemName="parent"
            />

            {authorizedPickupInfo.length > 0 && (
              <FormSummary
                data-cy="pickup-summary"
                title="Authorized pickup info"
                fields={form.authorizedPickupInfo}
                values={authorizedPickupInfo}
                itemName="authorized pickup"
              />
            )}
            <FormSummary
              data-cy="policy-summary"
              fields={form.policyWavier}
              values={values.policyWavier}
              itemName="custom"
              title="Policy & Waiver"
            />
          </div>

          <div className="register-form__review-col register-form__review-col--one-third">
            <div className="register-form__pay-submit" ref={this.bindPayEl}>
              <div className="register-form__pay-submit-title">Review the Enrollment Info before submitting</div>

              <ButtonV2 data-cy="submit" label={btnLabel} onClick={this.submit} disabled={this.isRecaptchaNotLoaded} />

              {payment.enabled && (
                <div className="register-form__pay-submit-charges">
                  {currency.getPrice(payment.amount)} charges for each registration
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="register-form__controls">
          <ButtonV2 data-cy="back" secondary label="Back" onClick={onBack} />
        </div>
      </div>
    );
  }
}

export default withGoogleReCaptcha(withRouter(Review));
