import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from 'components';
import { validateValues } from './utils';
import FormList from './FormList';
import omit from 'lodash/omit';

class AuthorizedPickupInfo extends Component {
  static propTypes = {
    fields: PropTypes.array,
    items: PropTypes.array,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  constructor(props) {
    super(props);

    this.state = {
      items: props.items.length > 0 ? props.items : []
    };
  }

  @bind
  updateItems(items) {
    if (items.length === 1 && this.isEmptyForm(items[0])) {
      items = [];
    }

    this.setState({ items });
  }

  @bind
  isEmpty() {
    const { items } = this.state;

    return (
      items.length === 0 || // If no items were added and no interaction happened
      (items.length === 1 && this.isEmptyForm(items[0])) // If there was an interaction and the form was cleared afterwards
    );
  }

  @bind
  validate() {
    const { fields } = this.props;
    const { items } = this.state;
    const requiredFieldNames = fields.filter(f => f.options.required).map(f => f.id);
    return this.isEmpty() || items.every(i => validateValues(requiredFieldNames, i));
  }

  isEmptyForm(values) {
    const form = omit(values, ['id']);
    return Object.keys(form)
      .map(k => values[k])
      .every(v => !v);
  }

  @bind
  submit() {
    const isValid = this.validate();

    if (!isValid) {
      Actions.showFlash('All required fields should be filled', 'alert');
      return;
    }

    this.props.onNext();
  }

  saveItems() {
    this.props.onSave('authorizedPickupInfo', this.state.items);
  }

  showDeleteMsg() {
    return Actions.showModal('Confirmation', {
      title: 'Delete student',
      description: 'This action is irreversible, the information will be lost.'
    });
  }

  render() {
    const { fields, schoolId, onRestart } = this.props;
    const { items } = this.state;

    return (
      <div className="register-form__kid-info">
        <FormList
          fields={fields}
          items={items}
          schoolId={schoolId}
          limit={10}
          itemName="Authorized Pickup"
          onChange={this.updateItems}
          onDelete={this.showDeleteMsg}
          onRestart={onRestart}
        />

        <div className="register-form__controls">
          <ButtonV2 data-cy="back" secondary label="Back" onClick={this.props.onBack} />
          <ButtonV2 data-cy="continue" label="Continue" onClick={this.submit} />
        </div>
      </div>
    );
  }
}

export default AuthorizedPickupInfo;
