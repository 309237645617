import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormRenderer from 'components/FormRenderer';
import ButtonV2 from 'components/ButtonV2';
import FormFieldType from 'constants/FormFieldType';
import { validateValues } from './utils';

class PolicyWavier extends Component {
  static propTypes = {
    fields: PropTypes.array,
    values: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  };

  static defaultProps = {
    values: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      values: { ...props.values }
    };
  }

  @bind
  updateValues(values) {
    this.setState({ values });
  }

  validate() {
    const requiredFields = this.props.fields
      .filter(f => (f.type === FormFieldType.TextBox ? f.options.hasCheckbox : f.options.required))
      .map(f => f.id);

    return validateValues(requiredFields, this.state.values);
  }

  @bind
  submit() {
    const isValid = this.validate(this.state.values);

    if (!isValid) {
      Actions.showFlash('Please read and accept all policies to continue.', 'alert');
      return;
    }

    this.props.onNext();
  }

  saveItems() {
    this.props.onSave('policyWavier', this.state.values);
  }

  render() {
    const { fields, schoolId, onRestart } = this.props;
    const { values } = this.state;

    return (
      <div className="register-form__policy-wavier">
        <FormRenderer
          fields={fields}
          defaultValues={values}
          onChange={this.updateValues}
          onRestart={onRestart}
          schoolId={schoolId}
        />

        <div className="register-form__controls">
          <ButtonV2 data-cy="back" secondary label="Back" onClick={this.props.onBack} />
          <ButtonV2 data-cy="continue" label="Continue" onClick={this.submit} />
        </div>
      </div>
    );
  }
}

export default PolicyWavier;
