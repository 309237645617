import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewportObserver from 'lib/ViewportObserver';
import Topbar from 'screens/common/Topbar';
import RegisterFormV2 from './RegisterFormV2';
import './style.scss';

class RegisterV2 extends Component {
  componentDidMount() {
    const viewport = document.getElementById('viewport');

    if (viewport) {
      ViewportObserver.observe(viewport);
    }
  }

  render() {
    return (
      <div className="register-v2">
        <Topbar />

        <div className="register-v2__inner">
          <Switch>
            <Route path="/form/:id" component={RegisterFormV2} />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default RegisterV2;
