import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getQuery } from 'lib/history';
import { ButtonV2, Placeholder } from 'components';
import PdfViewer from '../../teacher/Documents/common/PdfViewer';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import Layout from './Layout';
import store from '../../../../src/store';

const DocumentsSignatureRequest = ({ document, school, loading, match, history }) => {
  const pdfViewerRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const token = get(match, 'params.token');
  const query = getQuery() || {};
  const flags = useLaunchDarkly();

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          await req.signerEdoc({ token });
          await req.signerEdocSchool({ token });

        } catch (err) {
          console.log({ err });
          history.push('/documents');
        }
      }
    };

    fetchData();
  }, [token]); 
  
  if (loading || !document || !school) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (pdfViewerRef.current) {
        const annotations = await pdfViewerRef.current.getAnnotationsURL();
        const document = await req.updateSignerEdoc({
          token,
          email: query.email,
          document: {
            annotation_url: annotations
          }
        });
        setIsSubmitted(true);
        Actions.showFlash(`${document.title} has been submitted for approval`);
      }
    } catch (err) {
      Actions.reportError('There was problem while submitting document', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <Layout>
        <Placeholder.NoResult
          image="play"
          className="document-signature__no-results-placeholder document-signature__no-results-placeholder--play"
        >
          <h2>Thank you for submitting your document!</h2>
          {school && school.name} has received your document.
          <br />
          You will be notified once the document has been approved.
        </Placeholder.NoResult>
      </Layout>
    );
  }

  if (!['opened', 'request_sent'].includes(document.status)) {
    return (
      <Layout>
        <Placeholder.NoResult image="penguin2" className="document-signature__no-results-placeholder">
          <h2>This document has already been submitted.</h2>
          {school && school.name} has received your document.
          <br />
          You will be notified once the document has been approved.
        </Placeholder.NoResult>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="document-signature__pdfjs-viewer">
        <PdfViewer
          ref={pdfViewerRef}
          schoolId={school.id}
          fileUrl={document.merged_annotation_url || document.file_url}
          annotationsUrl={document.annotation_url}
        />
      </div>
      <div className="document-signature__pdfjs-viewer-actions">
        <ButtonV2 label="Submit" isLoading={isSubmitting} onClick={handleSubmit} />
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    document: state.signerEdoc.data,
    school: state.signerEdocSchool.data,
    loading: state.signerEdoc.loading
  };
};

const enhance = compose(connect(mapStateToProps), withRouter);

export default enhance(DocumentsSignatureRequest);
