import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Flashes, Placeholder, Preloader, RegistrationForm } from 'components';
import ModalController from 'modals/ModalController';
import './style.scss';

function RegisterFormV2({ match }) {
  const id = match.params.id;
  const form = useSelector(state => state.registrationForm.data);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    req
      .registrationForm({ id })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="register-form-v2__preloader">
        <Preloader large />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="content-container register-form-v2">
        <Placeholder.NoResult image="penguin2">
          This registration form is not available at the moment.
        </Placeholder.NoResult>
      </div>
    );
  }

  return (
    <GoogleReCaptchaProvider useEnterprise scriptProps={{ async: true }} reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
      <div className="register-form-v2">
        <RegistrationForm form={form} />
        <ModalController />
        <Flashes />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default RegisterFormV2;
