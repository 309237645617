import React from 'react';
import './style.scss';

function reloadApp() {
  window.location.reload(true);
}

const ErrorPage = () => (
  <div className="system-error">
    <header className="topbar">
      <div className="topbar__left">
        <a href="/" className="topbar__logo">
          <div className="topbar-logo" />
        </a>
      </div>
    </header>
    <div className="system-error__outer">
      <div className="system-error__body">
        <img className="system-error__img" src="/assets/images/crying-kid.png" />
        <h1 className="system-error__title">Uh..Oh!</h1>
        <p className="system-error__description">Something went wrong on our end. We've let our engineers know.</p>
        <a className="system-error__reload" href="/" onClick={reloadApp}>
          Try Again
        </a>
      </div>
    </div>
  </div>
);

export default ErrorPage;
