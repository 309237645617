import React, { useEffect, useState } from 'react';

import Roles from 'constants/roles';
import { Link } from 'components';

function SwitchSchoolRole({ teacherSchools, carerSchools, isStaff, isCarer, onSwitchRole }) {
  const [schoolRoles, setSchoolRoles] = useState([]);
  const [isBothRoles, setIsBothRoles] = useState(false);

  const mapSchoolRoles = () => [
    ...(teacherSchools?.map(s => ({ id: s.id, role: Roles.TEACHER })) || []),
    ...(carerSchools?.map(s => ({ id: s.id, role: Roles.CARER })) || [])
  ];

  const isBothParentAndTeacher = schoolRoles =>
    schoolRoles.some(s => s.role === Roles.TEACHER) && schoolRoles.some(s => s.role === Roles.CARER);

  const getSchoolIdByRole = (role, schoolRoles) => schoolRoles.find(s => s.role === role)?.id;

  useEffect(() => {
    const schoolRolesMap = mapSchoolRoles();
    setSchoolRoles(schoolRolesMap);
    setIsBothRoles(isBothParentAndTeacher(schoolRolesMap));
  }, [teacherSchools, carerSchools]);

  return !isBothRoles ? (
    <></>
  ) : (
    <>
      {isStaff && (
        <li className="topbar-menu__item topbar-menu__item--normal">
          <Link
            onClick={() => onSwitchRole(Roles.CARER, getSchoolIdByRole(Roles.CARER, schoolRoles))}
            underlined={false}
          >
            SWITCH TO PARENT
          </Link>
        </li>
      )}
      {isCarer && (
        <li className="topbar-menu__item topbar-menu__item--normal">
          <Link
            onClick={() => onSwitchRole(Roles.TEACHER, getSchoolIdByRole(Roles.TEACHER, schoolRoles))}
            underlined={false}
          >
            SWITCH TO STAFF
          </Link>
        </li>
      )}
    </>
  );
}

export default SwitchSchoolRole;
