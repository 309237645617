import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { matchPath } from 'react-router-dom';

import {
  CREATE_STAFF,
  CREATE_STUDENT,
  CREATE_LEAD,
  CREATE_EVENT,
  CREATE_INVOICE,
  DEFAULT_QUICK_ACTIONS_MAP
} from 'constants/schoolQuickActions';
import { isFree, getSubscriptionAccessLevel, getPermittedQuickActions } from 'lib/utils';
import history, { goToPage } from 'lib/history';
import { Avatar, Link, TooltipTrigger, Icon, ActionButton, ContextMenu, ContextMenuItem } from 'components';
import usePermissions from 'hooks/usePermissions';
import { RootContext } from 'hocs/withContext';
import SyncButton from './SyncButton';
import SwitchSchoolRole from './SwitchSchoolRole';

import cn from 'classnames';
import './style.scss';

const EDIT_QUICK_ACTIONS = 'edit-quick-actions';

const Topbar = className => {
  const context = useContext(RootContext);
  const { hasReadPermissions, hasWritePermissions } = usePermissions();
  const [showQuickActions, setShowQuickActions] = useState(true);
  const [showPremium, setShowPremium] = useState(true);
  const [showStaffMessaging, setShowStaffMessaging] = useState(true);
  const [showPendingSteps, setShowPendingSteps] = useState(true);
  const currentUser = useSelector(state => state.currentUser.data) || {};
  const unreadStaffMessages = get(currentUser, 'data.teacher.unread_staff_messages');
  const currentSchool = currentUser.current_school || {};
  const isAdmin = currentUser.teacher?.is_admin ?? false;
  const schools = currentUser[currentUser.current_role + '_schools'] || [];
  const peEnabled = get(currentUser, 'current_school.pe_enabled', false);
  const peSource = get(currentUser, 'current_school.pe_source', null);

  useEffect(() => {
    if (currentUser.id) {
      req.profile();
    }

    toggleButtons(history.location);
    const removeListener = history.listen(toggleButtons);

    return () => {
      if (typeof removeListener === 'function') {
        removeListener();
      }
    };
  }, []);

  const toggleButtons = location => {
    setShowStaffMessaging(location.pathname.indexOf('/onboarding') === -1);
    setShowQuickActions(location.pathname.indexOf('/onboarding') === -1);
    setShowPremium(location.pathname.indexOf('/dashboard/subscription') === -1);
    setShowPendingSteps(
      location.pathname.indexOf('/onboarding') === -1 && location.pathname.indexOf('/dashboard') === -1
    );
  };

  const pendoOpen = e => {
    if (window.pendo) {
      window.pendo.feedback.openFeedback(e);
    }
  };

  const showNotAllowed = e => {
    e.preventDefault();

    Actions.showFlash('Only Admins can subscribe to premium account', 'alert');
  };

  const onQuickAction = value => {
    if (value === EDIT_QUICK_ACTIONS) {
      goToPage('/profile');
    } else if ([CREATE_STAFF, CREATE_STUDENT, CREATE_LEAD].includes(value)) {
      Actions.showModal('CreatePerson', {
        action: value
      });
    } else if (value === CREATE_EVENT) {
      Actions.showModal(
        'EditCalendarEvent',
        {
          isQuickAction: true
        },
        { noCloseIcon: true }
      );
    } else if (value === CREATE_INVOICE) {
      Actions.showModal('CreateInvoice', {
        isQuickAction: true
      });
    }
  };

  const switchRole = (role, schoolId) => {
    if (!role || !schoolId) {
      return;
    }

    context.switchRole(role, schoolId);
  };

  const openSwitchSchool = () => {
    Actions.showModal('SelectSchool', { currentSchool, isSwitch: true, title: 'Switch School' });
  };

  const renderUserMenu = () => {
    const isStaff = currentUser.current_role === 'teacher';
    const isCarer = currentUser.current_role === 'carer';
    const userCommunityURL = 'https://greatquestion.co/procaresolutions/panel';
    const helpLink = 'https://www.procaresupport.com/procare-online' + (!isStaff ? '/docs/resources-for-parents' : '');

    return (
      <ul className="topbar-menu">
        <li className="topbar-menu__item topbar-menu__settings">
          <Link to="/profile" underlined={false}>
            <span className="topbar-menu__settings--name">{currentUser.name}</span>
            <span className="topbar-menu__settings--email">{currentUser.email}</span>
          </Link>
        </li>
        <SwitchSchoolRole
          teacherSchools={currentUser.teacher_schools}
          carerSchools={currentUser.carer_schools}
          isStaff={isStaff}
          isCarer={isCarer}
          onSwitchRole={switchRole}
        />
        {isStaff && currentUser.current_school.online_subscription && (
          <li className="topbar-menu__item topbar-menu__item--normal">
            {currentUser.teacher && currentUser.teacher.is_admin ? (
              <Link to="/dashboard/subscription" underlined={false}>
                SUBSCRIPTION
              </Link>
            ) : (
              <Link onClick={showNotAllowed} underlined={false}>
                SUBSCRIPTION
              </Link>
            )}
          </li>
        )}
        <li className="topbar-menu__item topbar-menu__item--normal">
          <Link to={helpLink} target="_blank" underlined={false}>
            HELP CENTER
          </Link>
        </li>
        {isStaff && (
          <li className="topbar-menu__item topbar-menu__item--normal">
            <Link onClick={pendoOpen} underlined={false}>
              GIVE FEEDBACK
            </Link>
          </li>
        )}
        {isStaff && (
          <li className="topbar-menu__item topbar-menu__item--normal">
            <Link to={userCommunityURL} target="_blank" underlined={false}>
              JOIN USER COMMUNITY
            </Link>
          </li>
        )}
        <li className="topbar-menu__item topbar-menu__sign-out" onClick={context.logout}>
          <Link data-cy="logout" underlined={false}>
            LOG OUT
          </Link>
        </li>
      </ul>
    );
  };

  const renderPremiumButton = () => {
    const difference =
      currentSchool &&
      currentSchool.trial_period_end_date &&
      moment(currentSchool.trial_period_end_date).diff(moment(), 'days');
    const trialExpired = difference < 0;

    return (
      <React.Fragment>
        <Icon name="star" size={24} />
        {trialExpired ? (
          <div>
            <div className="topbar-notifier__title">
              <span className="accent">Click</span> to upgrade to Essentials
            </div>
            <div className="topbar-notifier__subtitle">Your trial has expired</div>
          </div>
        ) : (
          <div>
            <div className="topbar-notifier__title">
              <span className="accent">Click</span> to keep Essentials Plan
            </div>
            <div className="topbar-notifier__subtitle">
              Your trial will expire {difference > 0 ? `in ${difference} days` : 'today'}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderQuickActions = () => {
    const isStaff = currentUser.current_role === 'teacher';
    const isCorp = currentSchool.corp_school;
    const quickActions = getPermittedQuickActions(currentUser, currentSchool);
    const quickActionsSettings = get(
      currentUser,
      `web_settings.${get(currentSchool, 'id')}.quickActions`,
      DEFAULT_QUICK_ACTIONS_MAP
    );

    if (
      !isStaff ||
      isCorp ||
      !showQuickActions ||
      !Helpers.checkFeatureAccess('quick-actions', false) ||
      !quickActions.length
    ) {
      return null;
    }

    return (
      <ContextMenu
        iconName="add-dark"
        iconSize={22}
        side="center"
        className="topbar__quick-actions"
        data-cy="quick-actions"
      >
        {quickActions
          .filter(action => quickActionsSettings[action.value])
          .map(action => {
            return (
              <ContextMenuItem
                key={action.value}
                className="topbar__quick-actions__item"
                value={action.value}
                label={action.label}
                onClick={onQuickAction}
                data-cy={`quick-action-${action.value}`}
              />
            );
          })}
        <ContextMenuItem
          key={EDIT_QUICK_ACTIONS}
          className="topbar__quick-actions__item topbar__quick-actions__item--edit"
          label="Edit Quick Actions"
          value={EDIT_QUICK_ACTIONS}
          onClick={onQuickAction}
        />
      </ContextMenu>
    );
  };

  const schoolName = currentSchool && currentSchool.name;
  const isStaff = currentUser.current_role === 'teacher';
  const freeUser = isFree(currentSchool) && isStaff;
  const onboardingIncomplete = freeUser && showPendingSteps;
  const showPremiumButton = !onboardingIncomplete && freeUser && showPremium;
  const topbarCN = cn(
    'topbar',
    {
      'topbar--premium': showPremiumButton
    },
    className
  );
  const category = getSubscriptionAccessLevel(currentSchool, false);
  const staffMessagingPath = matchPath(location.pathname, '/messaging') === null ? '/staff/messages' : '/messaging/staff'

  return (
    <header className={topbarCN}>
      <div className="topbar__left">
        <Link to="/" className="topbar__logo">
          <div className={cn('topbar-logo', `topbar-logo--${category}`)} />
        </Link>

        {schoolName && (
          <div className="topbar__welcome">
            <div className="topbar__welcome-school">
              <div className="topbar__welcome-school-inner">
                <div className="topbar__welcome-school-text">{schoolName}</div>

                <ActionButton
                  onClick={openSwitchSchool}
                  size={14}
                  iconName="chevron-down"
                  className="topbar__change-school"
                  hidden={schools.length <= 1}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {showPremiumButton &&
        (currentUser.teacher && currentUser.teacher.is_admin ? (
          <Link data-cy="premium-button" className="topbar-notifier" to="/dashboard/subscription">
            {renderPremiumButton()}
          </Link>
        ) : (
          <a data-cy="premium-button" className="topbar-notifier" onClick={showNotAllowed}>
            {renderPremiumButton()}
          </a>
        ))}

      {peSource && peEnabled && isAdmin && hasWritePermissions('school_settings') && <SyncButton />}

      {renderQuickActions()}
      {isStaff && !currentSchool.corp_school && showStaffMessaging && hasReadPermissions('teachers_messaging') && (
        <TooltipTrigger className="topbar__tooltip" tooltip="Staff Message" side="center">
          <Link
            className={cn('topbar-action-button', {
              'topbar-action-button--active': !!unreadStaffMessages
            })}
            to={staffMessagingPath}
          >
            <Icon size={25} name="message" className="topbar-action-button__icon" />
            {!Helpers.checkFeatureAccess('staff-messaging', false) && (
              <Icon name="premium-star" className="topbar-action-button__premium" />
            )}
          </Link>
        </TooltipTrigger>
      )}

      {currentUser.id && (
        <TooltipTrigger white tooltip={renderUserMenu()} triggerOn="click" side="right">
          <div className="topbar-avatar" data-cy="topbar-avatar">
            <Avatar size="small" url={currentUser.profile_pic_url} type="avatar" />
          </div>
        </TooltipTrigger>
      )}
    </header>
  );
};

Topbar.propTypes = {
  currentUser: PropTypes.object,
  currentSchool: PropTypes.object,
  onSwitchSchool: PropTypes.func,
  schools: PropTypes.array,
  peEnabled: PropTypes.bool,
  peSource: PropTypes.string
};

export default Topbar;
