import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonV2 from 'components/ButtonV2';
import FormFieldType from 'constants/FormFieldType';
import FormList from './FormList';
import { validateValues } from './utils';

class ParentInfo extends Component {
  static propTypes = {
    fields: PropTypes.array,
    items: PropTypes.array,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onSave: PropTypes.func
  };

  static defaultProps = {
    fields: [],
    items: []
  };

  form = null;

  constructor(props) {
    super(props);

    this.state = {
      items: props.items.length > 0 ? props.items : []
    };
  }

  componentDidMount() {
    this.forceUpdate();
  }

  @bind
  updateValues(items) {
    this.setState({ items });
  }

  @bind
  validate() {
    const { fields } = this.props;
    const { items } = this.state;
    const requiredFieldNames = fields
      .filter(f => f.type !== FormFieldType.Divider && f.type !== FormFieldType.TextBox && f.options.required)
      .map(f => f.id);

    return items.length > 0 && items.every(i => validateValues(requiredFieldNames, i));
  }

  @bind
  submit() {
    const isValid = this.validate();

    if (!isValid) {
      Actions.showFlash('All required fields should be filled', 'alert');
      return;
    }

    this.props.onNext();
  }

  saveItems() {
    this.props.onSave('parentInfo', this.state.items);
  }

  @bind
  bindForm(node) {
    this.form = node;
  }

  isFormValid() {
    return this.form === null ? undefined : this.form.isValid();
  }

  showDeleteMsg() {
    return Actions.showModal('Confirmation', {
      title: 'Delete Parent',
      description: 'Do you want to delete parent?'
    });
  }

  render() {
    const { fields, schoolId, onRestart } = this.props;
    const { items } = this.state;
    const isValid = this.isFormValid();

    return (
      <div className="register-form__parent-info">
        <FormList
          ref={this.bindForm}
          itemName="Parent/Guardian"
          fields={fields}
          items={items}
          limit={2}
          onChange={this.updateValues}
          onDelete={this.showDeleteMsg}
          onRestart={onRestart}
          schoolId={schoolId}
        />

        <div className="register-form__controls">
          <ButtonV2 secondary label="Back" data-cy="back" onClick={this.props.onBack} />
          <ButtonV2 label="Continue" data-cy="continue" onClick={this.submit} disabled={!isValid} />
        </div>
      </div>
    );
  }
}

export default ParentInfo;
