import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewportObserver from 'lib/ViewportObserver';
import Topbar from 'screens/common/Topbar';
import RegisterForm from '../RegisterForm';
import RegisterComplete from '../RegisterComplete';
import './style.scss';

class Register extends Component {
  componentDidMount() {
    const viewport = document.getElementById('viewport');

    if (viewport) {
      ViewportObserver.observe(viewport);
    }
  }

  render() {
    return (
      <div className="register">
        <Topbar />

        <div className="register__inner">
          <Switch>
            <Route exact path="/register/:slug/complete" component={RegisterComplete} />
            <Route path="/register/:slug" component={RegisterForm} />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Register;
